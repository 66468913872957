import { Icon } from '@seeqdev/qomponents';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isAIStandalone } from '@/main/routing.utilities';
import { setDarkMode } from '@/workbench/workbench.actions';
import { sqWorkbenchStore } from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { setAIAssistantFontSize } from '@/aiAssistant/aiAssistant.actions';
import { AiAssistantShare } from '@/aiAssistant/AiAssistantShare.atom';
import { ButtonWithPopover } from '@/core/ButtonWithPopover.molecule';
import { Dropdown } from 'react-bootstrap';

interface AiAssistantToolbarProps {
  expandHistory: () => void;
  newChat: () => void;
  isRunning: boolean;
  selectedChatName: string;
  shareChat: (name: string, userId: string) => void;
}

export const AiAssistantToolbar: React.FunctionComponent<AiAssistantToolbarProps> = ({
  expandHistory,
  newChat,
  isRunning,
  selectedChatName,
  shareChat,
}) => {
  const { t } = useTranslation();
  const darkMode = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.darkMode);
  const [shareIsOpen, setShareIsOpen] = React.useState(false);

  const renderDropdownEntry = ({ icon, label, onClick }: { icon: string; label: string; onClick: () => any }) => (
    <Dropdown.Item
      key={`dropdown_${label}`}
      onClick={onClick}
      className="sq-icon-hover dropdown-menu-sm cursorPointer pl30 pr30">
      <Icon icon={icon} extraClassNames="fa-fw mr5" />
      <span>{label}</span>
    </Dropdown.Item>
  );

  return (
    <>
      {shareIsOpen && (
        <AiAssistantShare
          selectedChatName={selectedChatName}
          onClose={() => setShareIsOpen(false)}
          shareChat={shareChat}
        />
      )}
      <div className="flexColumnContainer flexAlignCenter mr9 ml5 mt10 mb10">
        <div
          className="flexColumnContainer flexAlignCenter mr15 highlightTextOnHover cursorPointer"
          onClick={expandHistory}
          data-qtip-text={t('AI_ASSISTANT.HISTORY')}>
          <Icon icon="fa-clock-rotate-left" extraClassNames="ai-button" />
        </div>

        <ButtonWithPopover
          label={
            <div className="shareButton">
              <Icon icon="fa-text-size" extraClassNames="ai-button" />
            </div>
          }
          popoverConfig={{
            id: 'Menu',
            placement: 'bottom',
          }}>
          {[
            {
              icon: `fa-text font-size size-small`,
              label: t('AI_ASSISTANT.SIZE.SMALL'),
              onClick: () => setAIAssistantFontSize('small'),
            },
            {
              icon: `fa-text font-size size-medium`,
              label: t('AI_ASSISTANT.SIZE.MEDIUM'),
              onClick: () => setAIAssistantFontSize('medium'),
            },
            {
              icon: `fa-text font-size size-large`,
              label: t('AI_ASSISTANT.SIZE.LARGE'),
              onClick: () => setAIAssistantFontSize('large'),
            },
          ].map(renderDropdownEntry)}
        </ButtonWithPopover>

        <div className="flexColumnContainer flexFill" />
        {isAIStandalone() && (
          <Icon
            icon={darkMode ? 'fa-brightness' : 'fa-moon-stars'}
            onClick={() => setDarkMode(!sqWorkbenchStore.darkMode)}
            extraClassNames="mr15 cursorPointer ai-button"
            tooltip={darkMode ? t('DARK_MODE.DISABLE') : t('DARK_MODE.ENABLE')}
            tooltipPlacement="left"
          />
        )}
        {selectedChatName !== undefined && (
          <div
            className="flexColumnContainer flexAlignCenter highlightTextOnHover cursorPointer mr15"
            data-qtip-text={t('AI_ASSISTANT.SHARE.TITLE')}
            data-qtip-placement="left"
            onClick={() => !isRunning && setShareIsOpen(true)}>
            <Icon icon="fa-solid fa-share-from-square ai-button" type={isRunning ? 'gray' : 'theme'} />
          </div>
        )}
        <div
          className="flexColumnContainer flexAlignCenter highlightTextOnHover cursorPointer ai-button"
          data-qtip-text={t('AI_ASSISTANT.NEW_CHAT')}
          data-qtip-placement="left"
          onClick={() => {
            !isRunning && newChat();
          }}>
          <Icon icon="fa-plus" type={isRunning ? 'gray' : 'theme'} />
        </div>
      </div>
    </>
  );
};
